<template>
  <van-cell-group inset>
    <van-cell 
      title="订单编号"
      :value="order.id"
    />
    <van-cell 
      title="下单时间"
      :value="order.createTimeText"
    />
    <van-cell 
      title="订单状态"
      :value="order.statusText"
    />
  </van-cell-group>
  <van-cell-group inset>
    <van-cell>
      <van-card
        v-for="goods in order.goodsRefs"
        :key="goods.goodsId"
        :title="goods.goods.name"
        :num="goods.goodsNum"
        :desc="goods.goods.summary"
        :price="formatPrice(goods.goods.price, false)"
        :thumb="goods.goods.picture"
      >
      </van-card>
    </van-cell>
  </van-cell-group>
  <van-cell-group inset>
    <van-cell title="商品总额" :value="formatPrice(order.totalFee)" />
    <van-cell title="运费" :value="formatPrice(order.expressFee)" />
    <van-cell title="优惠金额" :value="formatPrice(order.discountFee)" />
  </van-cell-group>
  <van-cell-group inset v-if="phoneNumber">
    <van-cell title="配送手机号码" :value="phoneNumber" />
  </van-cell-group>
  <van-cell-group inset v-if="order.cardnumRefs && order.cardnumRefs.length > 0">
    <van-cell>
      <van-row v-for="cardnum in order.cardnumRefs" :key="cardnum.id" >
        <van-col span="4">卡号：</van-col>
        <van-col span="12">{{ cardnum.insuranceCardNum.number }}</van-col>
        <van-col span="4">密码：</van-col>
        <van-col span="4">{{ cardnum.insuranceCardNum.password }}</van-col>
      </van-row>
    </van-cell>
  </van-cell-group>

  <van-row class="count-down" v-if="order.status == 0">
    <van-col offset="12" span="12">
      还剩 <van-count-down :time="countDownTime" format="HH:mm:ss" @finish="cancelOrder" /> 去完成支付
    </van-col>
  </van-row>

  <van-submit-bar v-if="order.status == 0" :price="order.payAmount" button-text="去支付" @submit="gotoPay" />
</template>

<script>
import { Toast, Dialog } from 'vant'

export default {
  data() {
    return {
      order: [],
      countDownTime: 0
    }
  },

  created() {
    var params = this.$route.params
    if (params.id) {
      this.axios
        .post('/api/mall/loadOrderDetail', {id: params.id})
        .then(res=> {
          console.log(res.data)
          if (res.data.success) {
            this.order = res.data.data.detail
            if (this.order.status == 0) {
              var now = new Date()
              var limited = 15 * 60 * 1000
              var timestamp = new Date(res.data.data.detail.createTime)
              this.countDownTime = limited -(now - timestamp)
              console.log(this.countDownTime)
              if (this.countDownTime < 0) { // 订单超时
                this.cancelOrder()
              }
            }
          } else {
            Toast('当前订单不存在！')
            this.$router.back()
          }
        })
    } else {
      Toast('订单参数错误！')
      this.$router.back()
    }
  },

  computed: {
    phoneNumber() {
      if (this.order.logisticsList) {
        return this.order.logisticsList[0].phone
      }
      return ""
    },
    goodsCount() {
      var count = this.order.goodsRefs.reduce((total, item) => total + item.goodsNum, 0)
      return "共 "+ count +" 件"
    }
  },

  methods: {
    formatPrice(price, isMark) {
      if (isMark == null) {
        isMark = true
      }
      return (isMark ? "￥" : '') + (price / 100).toFixed(2)
    },

    gotoPay() {
      this.$router.push({ name: 'payment', params: { orderId: this.order.id }})
    },

    cancelOrder() {
      this.axios
        .post('/api/mall/cancelOrder', {id: this.order.id})
        .then(res=> {
          console.log(res.data)
          if (res.data.success) {
            this.$router.go(0)
          } else {
            Toast(res.data.message)
          }
        })
    }
  },
};
</script>

<style lang="less">
.van-cell-group {
  width: 94%;
  margin: 2% auto;

  .item {
    width: 90%;
    break-inside: avoid;
    margin-bottom: 10%;
    background-color: #fff;
    padding: 5%;
    border-radius: 10px;

    div {
      margin-bottom: 6px;
    }

    &-thumb {
      text-align: center;

      img {
        width: 100%;
      }
    }

    &-title {
      font-size: 14px;
      font-weight: bold;
    }

    &-desc {
      font-size: 12px;
      color: #666;
      overflow: hidden;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
      box-orient: vertical;
      display: -webkit-box;
      display: box;
    }

    &-price {
      color: #ff0000;
      font-size: 14px;

      span {
        font-size: 10px;
        margin-right: 5px;
      }
    }
  }
}
.count-down {
  font-size: .6rem;
  
  .van-count-down {
    display: inline-block;
    color: #ff0000;
  }

}
</style>